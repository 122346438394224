.main {
    /* height: 56px; */
  background-color: rgba(254, 254, 254, 1);
  /* background-color: red; */
  font-family: Raleway, Arial, Helvetica, sans-serif !important;
  font-size: 20px;
  font-weight: 550;
  padding: 8px 10vw;
  position: sticky;
  z-index: 10;
  top: 0;
  box-shadow: 0px 1px 240px 1px rgba(216, 216, 216, 0.24);
}

@media (max-width:991.98px){
  .main {
      padding: 16px 16px;
  }
}

.navbar-brand {
/* height: 8px; */
padding: 0;
margin: 0;
/* object-fit: cover; */
}

.navbar-logo {
height: 4px;
height: 4px;
padding: 0;
margin: 0;
object-fit: cover;
border-radius: 32px;
}

.navbar-lewagon {
  justify-content: space-between;
}

.navbar-lewagon .navbar-collapse {
  flex-grow: 0;
}

a {
  color: rgba(20, 15, 73, 1) !important;
}

a:hover {
  color: rgba(53, 161, 205, 1) !important;
}

hr {
    padding: 0;
    margin: 10px 0;
}
