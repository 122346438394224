.message-div {
    height: auto;
    margin-top: 16px;
    text-align: center;
    font-family: Raleway, Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 450;
    color: rgba(20, 15, 73, 0.85);
}

.message-footer {
    position: fixed;
    bottom: 0;
    width: 100%
}

.sorry-span {
    color:rgba(246,101,101,1);
    font-size: 24px;
    font-weight: 600;
}

.message-div-img {
    box-shadow: 0px 10px 24px  rgba(216, 216, 216, 0.24);
    box-shadow: 0px 10px 24px  red;
    box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.39);

}