html {
    scroll-behavior: smooth;
}


.App {
    background-color: rgba(254, 254, 254, 1);

}

.navbar-logo {
    height: 56px;
}

/*  main colors... */
/* rgba(20,15,73,1)
rgba(53,161,205,1)
rgba(254,254,254,1)
 */

/* accent colors */
/* rgba(255,194,97,1)
rgba(254,244,228,1)
rgba(88,206,255,1)
 rgba(233,248,255,1)
rgba(246,101,101,1)
rgba(253,175,175,1)
rgba(254,244,246,1)
rgba(102,89,255,1)
rgba(244,242,255,1) */
